<template>

  <v-container id="regular-tables" fluid tag="section">
     
  
  <v-container class="grey lighten-5" id="product__container">
  
    <div class="text-right">
       
       <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      </div>
    <!-- <CompanyAutoComplete /> -->
    
            <clientFilter
            @search="search"
            :data="data"
            @reset="reset"
            
            @showButtons="showButtons"/>
          </v-container>
          
     
    <v-row class="d-flex text-left"  style="padding-right:30px">
      

       <v-col cols="11"  style="position:relative;left:32px;padding-left:24px">
         <span v-if= "totalloginCount != ''" style="width:100px">
     <strong>Total Login : </strong>{{totalloginCount}} 
         </span>
          <span v-if= "totalloginCount != ''" style="padding-left:44px"> 
            
     <strong> Total Time : </strong>{{totalTime.split(":")[0]}} hours - {{totalTime.split(":")[1]}} minutes -- {{totalTime.split(":")[2]}} Seconds

    </span>
     

          </v-col>
         

       <v-col cols="1" >

      <v-btn  style="" v-if="data.length > 0" color="primary" small @click.stop="exportReport()"  v-show="checkIfOperationExistForModule('export')">Export

                  </v-btn>
       </v-col>
    </v-row>
    

    
   
   <DataTable :headers="headers" 
   ref="datatable"
    hide-default-footer
    disable-pagination
   :data="data"
   :item_key="item_key"
   :show_check_box="show_check_box"
   :totRecords="totRecords"
   :perpage="perpage"
   :isLoading="isLoading"
   :showexportbutton = "showexportbutton"
   :editItem="editItem"
   :get_data_api_url="get_data_api_url"
   :formType="formType"
   :show_add_delete_buttons ="show_add_delete_buttons"
   :fullpage="fullpage"
   
   @opendialog ="opendialog"/>


    
  </v-container>
</template>
<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DataTable from "./component/DataTable.vue";
import clientFilter from './component/clientFilter.vue';


  export default {
    components: { Loading,DataTable,clientFilter },
    data () {
      return {
        endpoints:{
          "GET_DATA_URL":null,
          "EXPORT_DATA_URL":null
         
        },
        get_data_api_url:null,
        data:[],
        item_key:"",
        totRecords :null,
        fullPage: false,
        perpage:50,
        isLoading:false,
        page:1,
        headers: [
                {
                    text: 'Name',
                    align: 'start',                   
                    value: 'name',
                    width:"35%",
                     sortable: false,
                    class: "v-data-table-header ",
                },
                
                {
                    text: 'Logins',
                    value: 'lcount',
                     sortable: false,
                   
                    class: "v-data-table-header",
                },
                {
                    text: 'Login Time(HH:MM:SS)',
                    value: 'timediff',
                     sortable: false,
                    width:"15%",
                    class: "v-data-table-header",
                },
                
                

        ],
        showButtons:true,
        search_company_id:null,
        dialog:false,
        added_company_id:null,
        updatedialog:false,
        sender_email:null,
        update_item_id:'',
        company_name:'',
        show_add_delete_buttons:false,
        opendialog:false,
        show_check_box:false,
        editItem:false,
        showexportbutton:false,
        totalloginCount:'',
        totalTime:'',
        companyName:'',
        sdate:'',
        edate:'',
        formType:''
        
        
      }
    },
    mounted() {
      var view = this
      view.get_data_api_url = view.$url("USER_TRACKER_REPORT")
      view.endpoints["GET_DATA_URL"] = view.$url("USER_TRACKER_REPORT")
      view.endpoints["EXPORT_DATA_URL"] = view.$url("EXPORT_DATA_URL")
      //view.getdata(view.endpoints["GET_DATA_URL"])
    },
    methods: {
      checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
      getdata:function(api_url){
         var view = this
         view.isLoading = true
        view.$utils.getData(api_url,view,view.page).then((res)=>{
            this.data = res.data.payload.data
            this.totRecords=res.data.payload.totaldata
            this.totalloginCount = res.data.payload.totallogin
            this.totalTime = res.data.payload.totaltime

            view.isLoading=false
          }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });

      },
     
     
      
       search: function (value) {
         var view = this
         let base_url = view.endpoints["GET_DATA_URL"]
         if(typeof value !== undefined){
           this.companyName = value["company"]
           this.sdate = value["sdate"]
           this.edate = value["edate"]
           let company_name = (value["company"] === undefined)?"":value["company"]
           let sdate = value["sdate"]
           let edate = value["edate"]
           let ep = base_url+"?company_name="+company_name+"&sdate="+sdate+"&edate="+edate

          view.getdata(ep)

         }
         
        //  let search_string = "company_id="+value
        //  let ep = base_url+"?"+search_string
         //view.page = 1;
    
         
    },
    reset:function(value){
      var view = this
         let base_url = view.endpoints["GET_DATA_URL"]

        //  let search_string = "company_id="+value
        //  let ep = base_url+"?"+search_string
         //view.page = 1;
         this.$refs.datatable.page = 1
          //this.data = ''
            this.totRecords=''
            this.totalloginCount = 0
            this.totalTime =''
         view.getdata(base_url)
         this.data = []

    },
    exportReport:function(){
      
      
           var view = this
           view.isLoading = true
           
           let ep = this.endpoints["EXPORT_DATA_URL"]+"?company_name="+this.companyName +"&sdate="+this.sdate+"&edate="+this.edate
           view.$utils.getData(ep,view,view.page).then((res)=>{
             window.location.href = ep
            view.isLoading=false
          }).catch((error) => {
              console.error(error);
              view.isLoading=false
        });

    }
    
        
    
    },
  }
</script>
